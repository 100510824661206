@charset "utf-8";

html {
  line-height: 1.6;
  color: #000;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
}

html, body {
  margin: 0;
  padding: 0;
}

.vbcn {
  *, :after, :before {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }
}

@import 'components-immobilien/vbcn/vbcn';
