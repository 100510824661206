.vbcn-placeholder {
  background: #fff;

  .vbcn-placeholder-headline {
    font-size: 1.3em;
    //margin-top: 0;
    margin: 0 0 0.3em;
    padding-bottom: 0.4em;
    border-bottom: 1px solid $color-text-dimmed;
    color: $color-highlight;
  }

  .vbcn-placeholder-content {
    background: #fff;
    border: 1px solid #ddd;
    padding: 20px;
  }

  .vbcn-placeholder-actions {
    text-align: right;
  }

  .vbcn-text-small {
    @include vbcn-text-small;
  }
}
