$color-highlight: #3b3a5a;
$color-text: #3b3a5a;
$color-text-dimmed: #666;
$color-error: #ff4e41;

@mixin vbcn-base-style {
  background: #a9b7c0;
  color: $color-text;
}

@mixin vbcn-button {
  background: #737373;
  color: #fff;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  padding: 0.6em 0.8em;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: bolder;
  margin: 0 0 0.5em 0.5em;

  &:hover,
  &:active {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25) inset;
  }

  &:disabled {
    color: #888;
    cursor: default;
  }

  &.primary {
    background: $color-highlight;
    color: #fff;
    font-weight: bolder;
  }
}

@mixin vbcn-text-small {
  font-size: 0.75em;
  color: $color-text-dimmed;
}
